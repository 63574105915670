<template>
    <div class="modal-notice modal-fullscreen">
        <div class="head">
            <div class="flex-row flex-between items-start">
                <div class="left flex-fill">
                    <div class="badges flex-row">
                        <div
                            v-if="notice.is_required"
                            class="badge badge-brd red flex-wrap"
                            v-html="$translate('NOTICE_REQUIRED')"
                        />
                        <div
                            v-if="notice.is_new"
                            class="badge badge-brd primary flex-wrap"
                            :class="{ 'm-l-4': notice.is_required }"
                            v-html="'NEW'"
                        />
                    </div>
                    <div
                        class="title f-bold"
                        v-html="$translate(notice.title)"
                    />
                </div>
                <i @click="$emit('close')" class="material-icons">close</i>
            </div>
            <div class="timestamp">
                {{ notice.created_at | formatDate('YY.MM.DD') }}
            </div>
        </div>
        <div class="hr" />
        <div class="body" v-html="notice.description" />
    </div>
</template>
<script>
export default {
    name: 'ModalNotice',
    props: ['options'],
    computed: {
        notice() {
            return (this.options || {}).notice || {}
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-notice {
    padding: 0 !important;

    .head {
        padding: 20px;

        .title {
            margin: 4px 0 8px;
            color: $grey-09;
            font-size: 18px;
        }

        .timestamp {
            font-size: 14px;
            color: $grey-05;
        }

        .material-icons {
            margin: 0;
        }
    }

    .hr {
        width: 100%;
        height: 1px;
        margin: auto;
        background: $grey-02;
    }

    .body {
        padding: 0 20px;
        line-height: 24px;
        max-height: calc(100vh - 120px);
        overflow-y: auto;
    }
}
</style>
